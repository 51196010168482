/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Kernkompetenz from './Kernkompetenz'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > div {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 30px;
    @media (min-width: ${props => props.theme.responsive.medium}) {
      padding: 30px 60px;
    }
  }

  .hidden {
    display: none;
    opacity: 0;
  }

  .extended .shown {
    display: none;
    opacity: 0;
  }

  .extended .hidden {
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: space-between;
    opacity: 1;
    width: 100%;
    @media (min-width: ${props => props.theme.responsive.medium}) {
      flex-direction: row;
      min-height: 50vh;
    }
  }

  a {
    border: 2px solid #000;
    padding: 20px 30px;
    width: 100%;
    display: inline-block;
    line-height: 1.2em;
    text-align: center;
    border-radius: 999px;
    margin-top: 10px;
    opacity: 1 !important;
    font-size: ${props => props.theme.fontSizes[2]}px;
    &:hover {
      background: #000;
      color: #fff;
    }

    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      padding: 10px;
      margin-top: 15px;
    }
  }
`

const HiddenLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  font-size: ${props => props.theme.fontSizes[3]}px;
  line-height: 2;
  padding-bottom: 15px;
  @media (min-width: ${props => props.theme.responsive.medium}) {
    padding-right: 60px;
    font-size: ${props => props.theme.fontSizes[6]}px;
    line-height: 1.5;
  }

  u {
    text-decoration: none !important;
    border: 2px solid #fff;
    padding: 5px 10px !important;
    border-radius: 0;
    white-space: nowrap;
  }

  u:first-of-type {
    border-radius: 99px;
  }

  u:hover {
    background-color: #fff;
    color: #000;
  }
`

const HiddenRight = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
  font-size: ${props => props.theme.fontSizes[1]}px;
  @media (min-width: ${props => props.theme.responsive.medium}) {
    width: 500px;
    font-size: ${props => props.theme.fontSizes[1]}px;
  }
`

const Number = styled.div`
  font-size: ${props => props.theme.fontSizes[7]}px;
  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    font-size: ${props => props.theme.fontSizes[3]}px;
  }
`

const ContentLeistungen = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulLeistungen(
        filter: { featured: { eq: true } }
        sort: { order: ASC, fields: order }
      ) {
        edges {
          node {
            title
            slug
            ubersichtKurzbeschreibung {
              json
            }
            ubersichtBeschreibung {
              json
            }
            farbe
            internal {
              type
            }
          }
        }
      }
      allContentfulProdukte(
        filter: { featured: { eq: true } }
        sort: { order: ASC, fields: order }
      ) {
        edges {
          node {
            title
            slug
            ubersichtKurzbeschreibung {
              json
            }
            ubersichtBeschreibung {
              json
            }
            farbe
            internal {
              type
            }
          }
        }
      }
    }
  `)

  const arrayLeistungen = data.allContentfulLeistungen.edges
  const arrayProdukte = data.allContentfulProdukte.edges
  const amount = Math.min(arrayLeistungen.length, arrayProdukte.length)
  let combined = []

  for (let i = 0; i < amount; i++) {
    combined.push(arrayLeistungen[i], arrayProdukte[i])
  }
  combined.push(
    ...arrayLeistungen.slice(amount),
    ...arrayProdukte.slice(amount)
  )

  return (
    <Container>
      {combined.map(({ node }, index) => (
        <Kernkompetenz
          key={node.slug}
          classTransfer={node.slug}
          farbe={node.farbe}
        >
          <div className="shown">{node.title}</div>
          <div className="hidden">
            <HiddenLeft>
              {node.ubersichtKurzbeschreibung &&
                documentToReactComponents(node.ubersichtKurzbeschreibung.json)}
            </HiddenLeft>
            <HiddenRight>
              <div>
                <Number>0{index+1}</Number>
                {node.ubersichtBeschreibung &&
                  documentToReactComponents(node.ubersichtBeschreibung.json)}
              </div>
              <Link
                to={
                  node.internal.type === 'ContentfulProdukte'
                    ? `/produkte/${node.slug}/`
                    : `/leistungen/${node.slug}/`
                }
              >
                Mehr zum Thema
              </Link>
              <Link to="/kontakt/">Unverbindliche Beratung</Link>
            </HiddenRight>
          </div>
        </Kernkompetenz>
      ))}
    </Container>
  )
}

export default ContentLeistungen
