/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'
import styled from '@emotion/styled'

const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 30px 60px;
  cursor: pointer;
  font-size: ${props => props.theme.fontSizes[3]}px;
  border-bottom: 2px solid #000;

  @media (min-width: ${props => props.theme.responsive.medium}) {
    min-height: 126px;
  }

  &:last-child {
    border-bottom: none !important;
  }

  .shown {
    font-size: ${props => props.theme.fontSizes[10]}px;
    line-height: 1.2em;
    @media (max-width: ${props => props.theme.responsive.largeMax}) {
      font-size: ${props => props.theme.fontSizes[7]}px;
    }
    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      font-size: ${props => props.theme.fontSizes[4]}px;
    }
    @media (max-width: ${props => props.theme.responsive.smallMax}) {
      font-size: ${props => props.theme.fontSizes[3]}px;
    }
  }
`

class Leistung extends React.Component {
  constructor(props) {
    super(props)
    this.toggleClass = this.toggleClass.bind(this)
    this.state = {
      active: false,
    }
  }
  toggleClass() {
    const currentState = this.state.active
    this.setState({ active: !currentState })
  }

  render() {
    return (
      <Container
        className={`${this.props.classTransfer} ${
          this.state.active ? 'extended' : 'retracted'
        }`}
        onClick={this.toggleClass}
        sx={{
          bg: `${this.props.farbe && this.props.farbe.toLowerCase()}`,
          '&:hover': {
            background: `gradient.${this.props.farbe &&
              this.props.farbe.toLowerCase()}`,
          },
        }}
      >
        {this.props.children}
      </Container>
    )
  }
}

export default Leistung

/** folgender Code war bei Container als Farben drinnen
  &:nth-of-type(4n-3) {
    background-color: ${props => props.theme.colors.red};
  }
  &.extended:nth-of-type(4n-3), &:nth-of-type(4n-3):hover {
    background: linear-gradient(
      90deg,
      ${props => props.theme.colors.red} 0%,
      ${props => props.theme.colors.background} 100%
    );
  }

  &:nth-of-type(4n-2) {
    background-color: ${props => props.theme.colors.green};
  }
  &.extended:nth-of-type(4n-2), &:nth-of-type(4n-2):hover {
    background: linear-gradient(
      90deg,
      ${props => props.theme.colors.green} 0%,
      ${props => props.theme.colors.background} 100%
    );
  }

  &:nth-of-type(4n-1) {
    background-color: ${props => props.theme.colors.purple};
  }
  &.extended:nth-of-type(4n-1), &:nth-of-type(4n-1):hover {
    background: linear-gradient(
      90deg,
      ${props => props.theme.colors.purple} 0%,
      ${props => props.theme.colors.background} 100%
    );
  }

  &:nth-of-type(4n) {
    background-color: ${props => props.theme.colors.grey};
  }
  &.extended:nth-of-type(4n), &:nth-of-type(4n):hover {
    background: linear-gradient(
      90deg,
      ${props => props.theme.colors.grey} 0%,
      ${props => props.theme.colors.background} 100%
    );
  }
  */
