import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import ContainerHero from '../components/ContainerHero'
import ContentKernkompetenzen from '../components/ContentKernkompetenzen'
import SEO from '../components/SEO'
import Newsletter from '../components/NewsletterPopup.js'

const Kernkompetenzen = () => {
  return (
    <Layout>
      <Newsletter />
      <SEO title="Kernkompetenzen" description="Kompetenz ist ein maßgeblicher Faktor in der IT-Betreuung. Hier finden Sie unsere fokussierten Tätigkeiten." />
      <Container>        
        <ContainerHero newsletter>
          <ContentKernkompetenzen />
        </ContainerHero>
      </Container>
    </Layout>
  )
}

export default Kernkompetenzen
